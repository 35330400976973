

.archive-title {
	/* Crem comunist ->> check constants.jsx */
	background-color: #af7d5e;
	color: white;
	margin-left: 10px;
	clip-path: polygon(0% 0%,100% 0%,88% 100%,0% 100%);
	padding: 0.5rem 1rem;
}
