.link {
  text-decoration: none;
  /* Roz comunist ->> check constants.jsx */
  color: #995b50;
  font-size: 0.6rem;
  letter-spacing: 0.12rem;
  line-height: 1.4;
  /* font-family: "myriad-pro", "Helvetica Neue", Helvetica, Arial, sans-serif,
    sans-serif; */
    font-family: "IBM BIOS"
}

.title-link{
  text-decoration: none;
  color:black;
  font-size: 1.27rem;
  font-weight: bold;
  line-height: 1.5rem;
  font-family: "IBM DOS";
  letter-spacing: .02rem;
}

.title-link:hover{
	/* Crem comunist */
	color: #fadca0;
}

/* Forgot why i put this */
/* .link:link {
  color: black;
} */

.link:hover {
  color: black;
}
