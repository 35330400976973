.email-field {
  width: 65%;
}

.email-message {
  font-family: "CORDATA";
  font-size: 0.9rem;
}

.subscribe-button {
  padding: 0.5rem;
  margin-left: 10px;
  font-family: "CORDATA";
  font-size: 0.9rem;
  border-radius: 5px;
  background: #af7d5e;
  color: white;
  cursor: pointer;
}

.subscribe-button:hover {
  background: #b28b73;
  color: rgb(221, 218, 218);
}

@media only screen and (min-width: 768px) {
  .subscribe-button {
    margin-left: 0.5rem;
    font-size: 1rem;
  }

  .email-field {
    width: 40%;
  }

}
