
.magazine-link {
	text-decoration: none;
	color: black;
	font-size: 1.3rem;
    line-height: 2;
	font-family: "IBM BIOS";
}

.magazine-link:visited {
	color: #af7d5e;
}

.magazine-link:hover  {
	color: #f9dea8;
}

.year {
	color: #bccdce;
	margin-left: 10px;
	float: none;
    font-size: 1.95rem;
    /* font-family: "myriad-pro-condensed","Helvetica Neue",Helvetica,Arial,sans-serif,sans-serif; */
	font-family: "IBM DOS";
	/* font-family: "Bebas Nue Pro"; */
    color: #bccdce;
    font-weight: 700;
    letter-spacing: .05rem;
}

.magazine-description {
	color: black;
	font-size: 0.83rem !important;
}

.magazine-description:hover {
	color: #f9dea8;
}